<template>
    <div @click="handleClick" class="p-4 bg-gray-50 rounded-xl flex flex-col justify-between items-start cursor-pointer"
        :class="(item.stateId === 2 || item.disabled) && 'opacity-50'">
        <div class="flex-shrink-0 flex justify-between w-full gap-2 items-center relative">
            <Checkbox :disabled="item.stateId === 2" :checked="item.selected" :toggle="toggleSelect" :label="item.fullname" />

            <div class="flex gap-4 text-xs font-medium" :class="stateColors[item.stateId]">
                {{ item.stateName }}
                <div class="pr-2 text-gray-600">
                    <i v-if="item.stateId === 2" class="fas fa-spinner fa-spin" />
                    <i v-else class="fas fa-chevron-down" :class="isOpen ? 'transform rotate-180' : ''" />
                </div>
            </div>
        </div>

        <div class="pl-10 w-full text-sm text-gray-500 h-full transition-all overflow-hidden"
            :class="isOpen ? 'opacity-100' : 'opacity-0'" :style="{ maxHeight: isOpen ? (height + 4) + 'px' : '0px' }">
            <ul ref="itemRef" class="divide-y divide-gray-200" @click.stop>
                <li class="flex justify-between gap-2 py-2 pr-2" v-for="question in item.questions" :key="question.id"
                    :class="questionLoading ? 'animate-pulse pointer-events-none' : ''">
                    <span v-if="editIndex !== question.id" class="text-gray-600 font-medium"
                        @dblclick="() => editQuestion(question.id)">
                        {{ formatText(question.name) }}
                    </span>

                    <input v-else v-model="currentEditValue" autofocus type="text"
                        class="bg-transparent border border-gray-300 rounded-md w-full px-2 py-1 m-0 resize-none"
                        @keydown.enter="() => saveQuestionHandle(question.id)" @blur="handleBlur"
                        @keydown.esc="cancelEdit" />

                    <button class="text-gray-400 hover:text-gray-600"
                        @click.stop="() => editIndex !== question.id ? editQuestion(question.id) : saveQuestionHandle(question.id)">
                        <i v-if="editedQuestion?.id === question.id && questionLoading"
                            class="fas fa-spinner animate-spin" />
                        <i v-else-if="editIndex !== question.id" class="fas fa-edit" />
                        <i v-else class="fas fa-check" />
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Checkbox from './interviewCheckbox.vue';

export default {
    props: {
        item: Object,
        isOpen: Boolean,
        toggleCollapse: Function,
        toggleSelect: Function,
        saveQuestion: Function,
        index: Number,
    },
    components: { Checkbox },
    data() {
        return {
            editedQuestion: null,
            editIndex: -1,
            height: 0,
            currentEditValue: '',
            questionLoading: false,
            stateColors: {
                2: 'text-yellow-500',
                3: 'text-green-500',
                10: 'text-red-500'
            }
        }
    },
    mounted() {
        if (this.$refs.itemRef) {
            this.updateHeight();
        }
    },
    methods: {
        handleClick(e) {
            e.stopPropagation();
            if (this.item.stateId === 2 || this.item.disabled) return;
            this.toggleCollapse();
        },
        formatText(text) {
            return text || '';
        },
        updateHeight() {
            if (this.$refs.itemRef) {
                this.height = this.$refs.itemRef.clientHeight;
            }
        },
        handleBlur() {
            if (this.currentEditValue !== this.editedQuestion?.name) {
                this.saveQuestionHandle(this.editIndex);
            } else {
                this.cancelEdit();
            }
        },
        cancelEdit() {
            this.editIndex = -1;
            this.editedQuestion = null;
            this.currentEditValue = '';
        },
        editQuestion(questionId) {
            const question = this.item.questions.find(q => q.id === questionId);
            if (question) {
                this.editedQuestion = { ...question };
                this.currentEditValue = question.name;
                this.editIndex = questionId;
            }
        },
        async saveQuestionHandle(questionId) {
            if (!this.currentEditValue.trim()) {
                this.cancelEdit();
                return;
            }

            this.questionLoading = true;
            try {
                await this.saveQuestion(this.index, questionId, {
                    id: this.editedQuestion.id,
                    name: this.currentEditValue.trim()
                });

                // Update local data immediately
                const questionToUpdate = this.item.questions.find(q => q.id === questionId);
                if (questionToUpdate) {
                    questionToUpdate.name = this.currentEditValue.trim();
                }
            } catch (err) {
                console.error('Error:', err);
            } finally {
                this.questionLoading = false;
                this.cancelEdit();
                this.updateHeight();
            }
        }
    },
    watch: {
        isOpen(newVal) {
            if (newVal) {
                this.$nextTick(this.updateHeight);
            }
        }
    }
}
</script>