<template>
  <div v-if="!load && List.length > 0" class="mt-2 overflow-y-auto" style="height: calc(100% - 110px)">
    <div class="flex justify-between gap-2 py-3 items-center bg-white border-b border-gray-200 px-3"
      v-for="(item, index) in List" :key="index">
      <h6 class="text-sm flex justify-between w-full items-center">
        {{ formatText(item.fileName) }}
        <span class="flex gap-5 items-center">
          <p class="text-red-500">Hatalı</p>
          <button @click="deleteItem(item)"> <i class="fa-solid fa-trash-can text-red-500 text-base"></i>
          </button>
        </span>

        <!-- <i class="fas fa-spinner fa-spin text-xl"></i> -->
      </h6>
    </div>
  </div>
</template>
<script>
import { cv } from "@/networking/urlmanager";

export default {
  name: "loading-cv",
  props: ["load"],
  data() {
    return {
      List: [],
    };
  },
  methods: {
    async getAll(loading = true) {
      try {
        this.$emit("load", loading);
        const res = await this.axios.get(cv.getCvTrain, {
          params: {
            stateId: 3,
          },
        });
        this.List = await res.data.data.map((r) => {
          try {
            return {
              id: r.id,
              data: this.listFormatController(r.completion),
              originalFile: r.originalFile,
              stateId: r.stateId,
              fileName: r.fileName,
              isView: 1,
            };
          } catch (error) {
            console.log(error);
            return {
              id: r.id,
              data: r.completion,
              originalFile: r.originalFile,
              stateId: r.stateId,
              fileName: r.fileName,
              isView: 1,
            };
          }
        });

        this.$emit("chanceList", this.List);
        this.$store.commit("extractCvList", this.List);
        this.$emit("load", false);
      } catch (err) {
        console.log(err);
        this.authController(err);
        this.$emit("load", false);
      }
    },
    async deleteItem(item) {
      try {
        const data = await this.verifiedMessage()
        if (data.isConfirmed) {
          await this.axios.post(cv.deleteTrain, {
            rowId: item.id,
          });
          this.getAll()
          this.successMessage();
        }
      } catch (err) {
        console.log(err);
        this.authController(err);
      }

    }
  },
};
</script>