<template>
  <div>
    <div class="p-5 h-[400px] scroltype xl:pb-1 pb-10">
      <div class="space-y-4">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div class="w-full relative">
            <!-- FİRMA İSMİ -->
            <customInput v-model="list.companyName" :disabled="(list.isCompanyControl == 1 && list.isCompany == 1) || isEdit
              " type="text" min="3" max="40" :required="true" :placeholder="$t('project.components.add.companyName')"
              :title="$t('project.components.add.companyName')" inputClass="w-full" />
            <div class="absolute right-0 top-1 flex items-center gap-2">
              <label for="myCompany" v-if="list.isCompanyControl == 1" class="text-xs textcol">{{
                $t("project.components.add.myCompany") }}</label>
              <input type="checkbox" v-model="list.isCompany" id="myCompany" class="w-4 h-4" @click="toggleIsCompany"
                v-if="!isEdit" />
            </div>
          </div>

          <!-- İŞ İLAN BAŞLIĞI -->
          <customInput v-model="list.projectName" type="text" min="3" max="40" :required="true"
            :placeholder="$t('project.components.add.jobPostingTitle')"
            :title="$t('project.components.add.jobPostingTitle')" />
          <!-- ORGANİZASYON POZİSYONU -->
          <div class="w-full">
            <label class="textcol font-semibold flex gap-1 items-center">{{
              $t("project.components.add.organizationPosition") }}
              <p class="text-[10px]">
                <i class="fa-sharp fa-solid fa-star-of-life text-[7px] text-red-600"></i>
              </p>
            </label>
            <positionSelector :isEdit="isEdit" v-model="list.positionList" />
          </div>
          <!-- KAPANMA TARİHİ -->
          <customInput v-model="list.endDate" type="date" :min="getNowDate" :required="true" :oldDate="true"
            :placeholder="$t('project.components.add.closingDate')" :title="$t('project.components.add.closingDate')" />

          <!-- ÖNCELİK -->
          <customInput v-model="list.priority" type="select" :selectList="[
            {
              value: 1,
              name: $t('project.components.add.priorityList.normal'),
            },
            {
              value: 2,
              name: $t('project.components.add.priorityList.urgent'),
            },
          ]" :required="true" :placeholder="$t('project.components.add.priority')"
            :title="$t('project.components.add.priority')" />

          <!-- İLAN TÜRÜ -->
          <customInput v-model="list.adType" type="select" :selectList="[
            {
              value: 1,
              name: $t('project.components.add.advertTypeList.open'),
            },
            {
              value: 2,
              name: $t('project.components.add.advertTypeList.hidden'),
            },
          ]" :required="true" :placeholder="$t('project.components.add.advertType')"
            :title="$t('project.components.add.advertType')" />

          <!-- İŞE ALINACAK KİŞİ SAYISI -->
          <!-- v-model="list.recruitmentCount" -->
          <customInput v-model="list.recruitmentCount" :value="Math.floor(list.recruitmentCount)" type="number" :min="1"
            :max="40" :required="true" :placeholder="$t('project.components.add.numberOfHiring')"
            :title="$t('project.components.add.numberOfHiring')" />

          <div class="w-full">
            <label class="textcol font-semibold">{{ $t("project.components.add.positionLanguage") }}</label>
            <languageSelect :isEdit="isEdit" :single-select="true" v-model="list.positionLanguage" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import positionSelector from "./positionList.vue";
import customInput from "@/components/customInput.vue";
import { mapState, mapMutations, mapGetters } from "vuex";
import languageSelect from "@/components/dev/languageSelect.vue";

export default {
  name: "steponePage",
  props: ["isEdit"],
  components: {
    positionSelector,
    customInput,
    languageSelect
  },
  computed: {
    ...mapState({
      list: (state) => state.project.stepData.stepOne,
    }),
    ...mapGetters({
      hasChanges: "hasChanges",
      getChanges: "getChanges",
    }),
  },
  data() {
    return {
      isFirstLoad: true,
    };
  },
  methods: {
    ...mapMutations({
      updateProjectStepOne: "SET_STEP_ONE_DATA",
    }),
    toggleIsCompany() {
      if (this.list.isCompanyControl !== true || this.isEdit) return;

      if (this.list.isCompany === false) {
        this.updateProjectStepOne({
          isCompany: true,
          companyName: this.$store.state.userData.companyName,
        });
      } else {
        this.updateProjectStepOne({
          isCompany: false,
          companyName: "",
        });
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.toggleIsCompany();
    }, 50);
    if (!this.isEdit && this.isFirstLoad) {
      this.updateProjectStepOne({
        isCompany: false,
        isCompanyControl: true,
      });
      this.isFirstLoad = false;
    }
  },
};
</script>
