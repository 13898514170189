<template>
  <div class="border bordercol w-full flex text-sm rounded-lg shadow-sm h-10 overflow-hidden">
    <button
      v-for="(item, index) in list"
      @click="toggleButton(index)"
      :key="index"
      class=" w-full relative z-10 transition-all duration-200 ease-in-out"
      :class="{
        'bgcol text-white': active == index,
        'hover:bg-gray-50': active != index,
        'border-r bordercol': index + 1 != list.length,
      }"
    >
      <div class="flex items-center justify-center gap-3">
        {{ item.name }}
        <span 
          class="min-w-[22px] h-[22px] flex justify-center items-center rounded-full text-xs font-medium transition-all duration-200"
          :class="{
            'bg-white bg-opacity-20 text-white': active == index,
            'bg-gray-100 text-gray-600': active != index,
            'animate-pulse': counts[index] > 5
          }"
        >
          {{ counts[index] }}
        </span>
      </div>
      <div 
        v-if="active == index" 
        class="absolute bottom-0 left-0 w-full h-0.5 bgcol"
      ></div>
    </button>
  </div>
</template>

<script>
export default {
  name: "navbar-button",
  props: {
    load: Boolean,
    list: Array,
    counts: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    toggleButton(index) {
      if (!this.load) {
        this.active = index;
        this.$emit("change", index);
      }
    },
  },
};
</script>