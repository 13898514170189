<template>
  <modal
    name="add-school"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{ $t("cv.profile.schoolAndEducation.components.add.add") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="isEdit ? add() : save()"
      class="p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20"
      novalidate
    >
      <div class="space-y-4 pb-3">
        <multiSelectVue
          :placeholder="
            $t('cv.profile.schoolAndEducation.components.add.scoolName')
          "
          :label="$t('cv.profile.schoolAndEducation.components.add.scoolName')"
          ref="SchoolNameRef"
          v-model="SchoolName"
          :required="true"
          :getOptions="getSchools"
        />

        <multiSelectVue
          :placeholder="
            $t(
              'cv.profile.schoolAndEducation.components.add.departmentPlacholder'
            )
          "
          :label="
            $t(
              'cv.profile.schoolAndEducation.components.add.departmentPlacholder'
            )
          "
          ref="departmentRef"
          v-model="department"
          :required="true"
          :getOptions="getDepartments"
        />

        <customInput
          v-model="typeName"
          type="select"
          :selectList="typeList"
          :required="true"
          :is-error="true"
          ref="typeName"
          :placeholder="
            $t('cv.profile.schoolAndEducation.components.add.typePlacholder')
          "
          :title="$t('cv.profile.schoolAndEducation.components.add.type')"
        />

        <div class="grid grid-cols-2 gap-2">
          <div>
            <label class="textcol font-semibold flex gap-1 items-center"
              >{{
                $t("cv.profile.schoolAndEducation.components.add.startDate")
              }}
              <p class="text-[10px]">
                <i class="fa-sharp fa-solid fa-star-of-life text-[7px]"></i>
              </p>
            </label>
            <datepicker
              v-model="periotStart"
              :format="DatePickerFormat"
              minimum-view="year"
              name="datepicker"
              id="input-id"
              :input-class="'w-full border bordercol rounded py-3 outline-none px-3 text-xs'"
            >
            </datepicker>
          </div>
          <div>
            <label class="textcol font-semibold flex gap-1 items-center">{{
              $t("cv.profile.schoolAndEducation.components.add.finshDate")
            }}</label>
            <datepicker
              v-model="periotEnd"
              :format="DatePickerFormat"
              minimum-view="year"
              name="datepicker"
              id="input-id"
              :input-class="'w-full border bordercol rounded py-3 outline-none px-3 text-xs'"
            >
            </datepicker>
          </div>
        </div>

        <customInput
          v-model="doesItContinue"
          type="select"
          :selectList="[
            {
              value: 1,
              name: 'Continues',
            },
            {
              value: 2,
              name: 'Completed',
            },
          ]"
          :placeholder="
            $t(
              'cv.profile.schoolAndEducation.components.add.situationPlacholder'
            )
          "
          :title="$t('cv.profile.schoolAndEducation.components.add.situation')"
        />

        <requiredField />
        <div
          class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
        >
          <div class="w-full">
            <buttonItem
              :load="load"
              :name="$t('cv.profile.schoolAndEducation.components.add.save')"
              buttonClass="w-full"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import moment from "moment";
import datepicker from "vuejs-datepicker";
import requiredField from "@/components/requiredField.vue";

import { educationLife, utility } from "@/networking/urlmanager.js";
export default {
  name: "addProject",
  props: ["isEdit"],
  components: {
    customInput,
    buttonItem,
    datepicker,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,

      SchoolName: null,
      department: null,
      periotStart: "",
      periotEnd: "",
      typeName: 0,
      doesItContinue: 0,

      DatePickerFormat: "yyyy",

      typeList: [
      {
          value: 0,
          name: "Unspecified",
        },
        {
          value: 1,
          name: "High School",
        },
        {
          value: 2,
          name: "Associate Degree",
        },
        {
          value: 3,
          name: "Bachelor’s Degree",
        },
        {
          value: 4,
          name: "Master’s Degree",
        },
        {
          value: 5,
          name: "Doctorate",
        },
      ],
    };
  },
  methods: {
    show() {
      this.$modal.show("add-school");
    },
    hide() {
      this.$modal.hide("add-school");
    },
    save() {
      this.load = true;

      this.checkErrors();

      if (this.SchoolName.name?.trim() && this.department.name?.trim()) {
        this.$store.commit("addSchool", {
          name: this.SchoolName,
          department: this.department,
          duration: this.periotStart + "-" + this.periotEnd,
          formatDate: this.periotStart + "-" + this.periotEnd,
          status: this.typeName,
          doesItContinue: this.doesItContinue,
        });

        this.SchoolName = "";
        this.department = "";
        this.periotStart = "";
        this.periotEnd = "";
        this.typeName = "";
        this.doesItContinue = 0;

        if (!this.isEdit) {
          this.successMessage();
        }

        this.load = false;
        if (this.isEdit) {
          this.$emit("refresh", true);
        }
        this.hide();
      } else {
        this.load = false;
      }
    },
    add() {
      this.load = true;

      this.checkErrors();

      let list = [
        {
          name: this.SchoolName,
          department: this.department,
          duration: this.periotStart + "-" + this.periotEnd,
          formatDate: this.periotStart + "-" + this.periotEnd,
          status: this.typeName,
          doesItContinue: this.doesItContinue,
        },
      ];
      let schoolAndEducation =
        this.$store.state.extractDetail.schoolAndEducation;

      this.axios
        .post(educationLife.add, {
          cvId: this.$route.params.id,
          list: JSON.stringify(list),
          educationList: schoolAndEducation,
        })
        .then((res) => {
          this.successMessage(res.data.message);
          this.save();
          this.load = false;
          this.hide();
        })
        .catch((err) => {
          console.log(err);
          this.authController(err);
          this.load = false;
        });
    },
    educationCalculator(val) {
      if (val.length > 0) {
        let list = val.sort(function (a, b) {
          return b.status - a.status;
        });
        return this.typeName > list[0].status ? this.typeName : list[0].status;
      } else {
        return this.typeName;
      }
    },
    async getSchools() {
      const response = await this.axios.get(utility.getInstitutionAll);
      return response.data.data;
    },
    async getDepartments() {
      const response = await this.axios.get(utility.getDepartmentAll);
      return response.data.data;
    },
    checkErrors() {
      this.$refs.SchoolNameRef.validate();
      this.$refs.departmentRef.validate();
      this.$refs.typeName.validateInput();
    },
  },
  watch: {
    periotStart(newYear) {
      const dateObject = moment(newYear);
      this.periotStart = dateObject.format("YYYY");
    },
    periotEnd(newYear) {
      const dateObject = moment(newYear);
      this.periotEnd = dateObject.format("YYYY");
    },
  },
};
</script>

<style>
.vdp-datepicker__calendar {
  width: 100% !important;
  margin-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  padding: 10px 10px;
}

.vdp-datepicker__calendar .cell {
  height: 35px !important;
  line-height: 35px !important;
  font-size: 13px;
}

.vdp-datepicker__calendar header span {
  font-size: 14px;
}
</style>
