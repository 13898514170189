<template>
  <modal name="pre-interview-detail" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '60%'" :scrollable="true">
    <div class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white z-50">
      <h1 class="">{{ $t("project.detail.preInterviewDetail.title") }}</h1>
      <button @click="hide()" type="button">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <topBar @click="(r) => getAll(r)" :load="load" />

    <div class="px-3 py-3 relative">
      <div class="tableOverflow scroltypey">
        <div class="tableWidth">
          <div class="tableContainer !border-0 !px-0">
            <div class="tableHead !bg-gray-200 !textcol !static">
              <ul class="tableUl">
                <li class="w-1/12">#</li>
                <li class="w-5/12">
                  {{ $t("project.detail.preInterviewDetail.question") }}
                </li>
                <li class="w-6/12 flex justify-between items-center pl-4">
                  {{ $t("project.detail.preInterviewDetail.answer") }}
                  <div class="w-[300px] flex items-center border border-[#182451] rounded-md h-9 relative">
                    <input v-model="search" @keypress.enter="getSearch()" type="text" class="h-full w-full outline-none pl-3 placeholder:textcol font-light rounded-md text-black pr-16" :placeholder=" $t('project.detail.preInterviewDetail.search')" />
                    <button class="px-3 textcol absolute right-0">
                      <i @click="getSearch()" class="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
            <div class="tableBody scroltype !px-0 !mt-1" :class="List.length > 5 ? '!pr-4' : ''"
            >
              <div class="tableBodyContainer">
                <ul v-for="(item, index) in List" :key="index" class="tableBodyUl !px-8">
                  <li class="w-1/12 py-2.5">{{ index + 1 }}</li>
                  <li class="w-5/12 py-2.5">
                    {{ item.question ? formatText(item.question) : $t("project.detail.preInterviewDetail.noQuestion") }}
                  </li>
                  <li class="w-6/12 py-2.5 text-justify pl-4">
                    {{  item.answer ? formatText(item.answer) : $t("project.detail.preInterviewDetail.notAnswer") }}
                  </li>
                </ul>
                <tableLoader :load="load" :length="List.length" colspan="7" />
              </div>
            </div>
          </div>
          <div class="text-sm w-full">
            <p class="text-gray-900 font-semibold">
              <span>{{ $t("project.detail.preInterviewDetail.preInterviewScore") }}</span>
              {{ Detail.recomendedInterviewScore }}
            </p>
            <p>
              <span class="text-gray-900 font-semibold">{{ $t("project.detail.preInterviewDetail.preInterviewNote") }}</span>
              {{ Detail.recomendedReason }}
            </p>
          </div>
          <div
            class="w-full border-t border-gray-100 pt-2"
            v-if="Detail.stateId == 7 || Detail.stateId == 8 || Detail.stateId == 9"
          >
            <form @submit.prevent="addShortList">
              <customInput
                v-if="Detail.stateId < 8"
                v-model="comment"
                :title="$t('project.detail.preInterviewDetail.note')"
                type="textarea"
                max="500"
                :placeholder="$t('project.detail.preInterviewDetail.yourComment')"
                :rows="4"
              />

              <customInput
                v-if="Detail.stateId >= 8"
                :value="Detail.stateId == 8 ? Detail.inadequateNote : comment"
                :title="$t('project.detail.preInterviewDetail.note')"
                type="textarea"
                :disabled="true"
                max="500"
                :placeholder="  $t('project.detail.preInterviewDetail.yourComment')
                "
                :rows="4"
              />

              <label
                class="text-red-500 text-sm font-bold"
                v-if="Detail.stateId == 8 || Detail.stateId == 9"
              >
                {{
                  Detail.stateId == 8
                    ? $t("project.detail.preInterviewDetail.markedInsufficient")
                    : $t("project.detail.preInterviewDetail.addedShortList")
                }}
                <br />
                <span
                  v-if="Detail.stateId == 8 || Detail.stateId == 9"
                  class="text-xs text-black"
                > {{ Detail.stateId == 8 ? Detail.inadequateDate : Detail.addShortListDate | formatDate}}</span
                >
              </label>
              <div class="flex justify-end" v-if="Detail.stateId < 8">
                <buttonItem
                  :load="saveLoad"
                  :disabled="indidateLoad"
                  :text="$t('project.detail.preInterviewDetail.addShortList')"
                  buttonClass="w-full"
                />

                <buttonItem
                  :load="indidateLoad"
                  :disabled="saveLoad"
                  type="button"
                  @click="() => setInadequate()"
                  :text="
                    $t('project.detail.preInterviewDetail.markUnsatisfactory')
                  "
                  buttonClass="w-full ml-4 bg-red-500"
                  containerBackground="bg-red-500"
                />

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import tableLoader from "@/components/table-loader.vue";
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import topBar from "./components/topBar.vue";

import {
  shortList,
  projectInterview,
  projectConnect,
} from "@/networking/urlmanager";

export default {
  name: "log-component",
  props: ["Detail", "projectId", "rowId"],
  components: {
    tableLoader,
    customInput,
    buttonItem,
    topBar,
  },
  data() {
    return {
      search: "",
      List: [],
      load: false,
      indidateLoad: false,
      comment: "",

      saveLoad: false,
    };
  },
  methods: {
    show() {
      this.comment = "";
      this.$modal.show("pre-interview-detail");
      setTimeout(() => {
        if (this.Detail.cvId > 0) this.getAll();
      }, 50);
    },
    hide() {
      this.$modal.hide("pre-interview-detail");
    },
    getSearch() {
      this.currentPage = 0;
      this.getAll();
    },
    getAll(type = 1) {
      this.load = true;
      this.List = [];
      this.axios.get(projectInterview.getQuestionAnswer, {
          params: {
            cvId: this.Detail.cvId,
            projectId: this.projectId,
            search: this.search,
            page: this.currentPage,
            rowId: this.Detail.id,
            type: type,
          },
        })
        .then((res) => {
          this.List = res.data.data.data;
          this.comment = res.data.data.comment;
          this.load = false;
        })
        .catch((err) => {
          this.authController(err);
          this.load = false;
        });
    },
    addShortList() {
      if (this.Detail.stateId >= 8) {
        this.warningMesage(
          this.$t("project.detail.preInterviewDetail.candidateNoAction")
        );
        return;
      }

      this.saveLoad = true;
      this.axios
        .post(shortList.add, {
          rowId: this.Detail.id,
          fullname: this.Detail.fullname,
          interviewScore: this.Detail.recomendedInterviewScore,
          interviewNote: this.comment ? this.comment : this.Detail.recomendedReason,
          projectId: this.projectId,
          projectName: this.$route.query.projectName,
        })
        .then((res) => {
          this.successMessage(res.data.message);
          this.saveLoad = false;
          this.hide();
          this.$emit("refresh", true);
        })
        .catch((err) => {
          this.authController(err);
          this.saveLoad = false;
        });
    },
    async setInadequate() {
      if (this.Detail.stateId == 8) {
        this.warningMesage(
          this.$t(
            "project.detail.preInterviewDetail.candidateAlreadyInadequate"
          )
        );
        return;
      }

      if (this.comment?.length < 5) {
        this.warningMesage(
          this.$t("project.detail.preInterviewDetail.charactersLong")
        );
        return;
      }

      this.indidateLoad = true;
      try {
        const res = await this.axios.post(projectConnect.setInadequate, {
          rowId: this.Detail.id,
          inadequateNote: this.comment,
          fullname: this.Detail.fullname,
          projectName: this.$route.query.projectName,
        });

        this.successMessage(res.data.message);
        this.indidateLoad = false;

        this.hide();
        this.$emit("refresh", true);
      } catch (error) {
        console.log(error);
        this.authController(error);
        this.indidateLoad = false;
      }
    },
  },
  watch: {
    currentPage() {
      this.getAll();
    },
    search: function (newSearch) {
      if (newSearch.length === 0) {
        this.getAll();
      }
    },
  },
};
</script>
