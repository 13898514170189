<template>
  <modal
    name="edit-language"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '30%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{ $t("cv.profile.information.languages.edit.edit") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="isEdit ? edit() : save()"
      class="p-5 max-h-screen xl:pb-5 pb-20"
    >
      <div class="space-y-4 pb-3">
        <span v-if="!isEdit"
          >{{ $t("cv.profile.information.languages.edit.language") }}
          <b>{{ languageDefault }}</b></span
        >
        <multiSelectVue
          :taggable="false"
          :placeholder="
            $t('cv.profile.information.languages.languageSelect.choose')
          "
          :label="
            $t('cv.profile.information.languages.languageSelect.language')
          "
          ref="languageRef"
          v-model="language"
          :required="true"
          :getOptions="getLang"
        />

        <customInput
          v-model="degree"
          type="select"
          :selectList="languageDegree"
          :placeholder="
            $t('cv.profile.information.languages.edit.levelPlacholder')
          "
          :title="$t('cv.profile.information.languages.edit.level')"
        />

        <requiredField />
        <div
          class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
        >
          <div class="w-full">
            <buttonItem
              :load="load"
              :name="$t('cv.profile.information.languages.edit.save')"
              buttonClass="w-full"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";

import { language, utility } from "@/networking/urlmanager.js";

export default {
  name: "edit-language",
  props: ["isEdit", "Detail", "index"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,

      rowId: "",
      language: null,
      degree: 0,
      languageDefault: "",

      languageDegree: [
        {
          value: 0,
          name: "Unspecified",
        },
        {
          value: 1,
          name: "Beginner Level",
        },
        {
          value: 2,
          name: "Intermediate Level",
        },
        {
          value: 3,
          name: "Advanced Level",
        },
        {
          value: 4,
          name: "Native Language Level",
        },
      ],
    };
  },
  methods: {
    show() {
      setTimeout(async () => {
        this.degree = this.Detail.level;
        this.rowId = this.Detail.id;
        this.languageDefault = this.Detail.name;

        if (this.isEdit) {
          this.language = {
            id: this.Detail.rowId,
            name: this.Detail.name,
          };
        } else {
          this.language = this.Detail.name;
        }
      }, 100);
      this.$modal.show("edit-language");
    },
    hide() {
      this.$modal.hide("edit-language");
    },
    save() {
      this.load = true;

      this.$refs.languageRef.validate();
      if (this.language) {
        this.$emit("loadChance", true);
        this.$store.commit("editLanguage", {
          index: this.index,
          list: {
            name: this.language,
            level: this.degree,
          },
        });

        this.language = "";
        this.degree = 0;
        this.successMessage();

        this.load = false;
        this.$emit("loadChance", true);
        this.hide();
      } else {
        this.load = false;
      }
    },
    async getLang() {
      const response = await this.axios.get(utility.getLanguagesAll);
      return response.data.data;
    },
    edit() {
      this.load = true;
      this.$refs.languageRef.validate();
      if (this.language) {
        this.axios
          .post(language.edit, {
            rowId: this.rowId,
            cvId: this.$route.params.id,
            languageId: this.language.id,
            level: this.degree,
          })
          .then((res) => {
            this.successMessage(res.data.message);
            this.$emit("refresh", true);
            this.load = false;
            this.hide();
          })
          .catch((err) => {
            console.log(err);
            this.authController(err);
            this.load = false;
          });
      } else {
        this.load = false;
      }
    },
  },
};
</script>
