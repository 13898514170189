var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-[400px]"},[_c('div',{staticClass:"p-5 scroltype h-full xl:pb-1 pb-10"},[_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-2 gap-5"},[_c('educationSelect',{model:{value:(_vm.list.educationType),callback:function ($$v) {_vm.$set(_vm.list, "educationType", $$v)},expression:"list.educationType"}}),_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"text textcol font-semibold"},[_vm._v(_vm._s(_vm.$t("project.components.add.school")))]),_c('institutionSelect',{attrs:{"isEdit":_vm.isEdit,"educationType":_vm.list.educationType},model:{value:(_vm.list.institutionList),callback:function ($$v) {_vm.$set(_vm.list, "institutionList", $$v)},expression:"list.institutionList"}})],1),_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"text textcol font-semibold"},[_vm._v(_vm._s(_vm.$t("project.components.add.section")))]),_c('departmentSelect',{attrs:{"isEdit":_vm.isEdit},model:{value:(_vm.list.departmentList),callback:function ($$v) {_vm.$set(_vm.list, "departmentList", $$v)},expression:"list.departmentList"}})],1),_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"textcol font-semibold"},[_vm._v(_vm._s(_vm.$t("project.components.add.foreignLanguage")))]),_c('languageSelect',{attrs:{"isEdit":_vm.isEdit},model:{value:(_vm.list.languageList),callback:function ($$v) {_vm.$set(_vm.list, "languageList", $$v)},expression:"list.languageList"}})],1),_c('customInput',{attrs:{"type":"select","selectList":[
          {
            value: 1,
            name: _vm.$t('project.components.add.workingModelList.remoteWork'),
          },
          {
            value: 2,
            name: _vm.$t('project.components.add.workingModelList.hybridWork'),
          },
          {
            value: 3,
            name: _vm.$t(
              'project.components.add.workingModelList.workingOfficeField'
            ),
          },
        ],"required":true,"placeholder":_vm.$t('project.components.add.workingModel'),"title":_vm.$t('project.components.add.workingModel')},model:{value:(_vm.list.workingModel),callback:function ($$v) {_vm.$set(_vm.list, "workingModel", $$v)},expression:"list.workingModel"}}),_c('div',[_c('label',{staticClass:"textcol font-semibold flex gap-1 items-center"},[_vm._v(_vm._s(_vm.$t("project.components.add.WorkCity"))+" ")]),_c('citySelect',{attrs:{"isEdit":_vm.isEdit},model:{value:(_vm.list.cityList),callback:function ($$v) {_vm.$set(_vm.list, "cityList", $$v)},expression:"list.cityList"}})],1)],1),_c('div',{staticClass:"pt-5"},[_c('experienceRange',{model:{value:(_vm.list.experienceDuration),callback:function ($$v) {_vm.$set(_vm.list, "experienceDuration", $$v)},expression:"list.experienceDuration"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }